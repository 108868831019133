import React, { useEffect, useState, useRef } from 'react';
import Nav from './components/Nav';
import { openFullscreen } from './components/Model';
import settings from './config.json';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import {  IoShareOutline, IoCloseOutline } from "react-icons/io5";
import { RiFullscreenLine, RiCalendarLine, RiFacebookLine, RiTwitterLine } from "react-icons/ri";
import './App.css';

function AppInner() {

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState('');
  const [currentCue, setCurrentCue] = useState('');
  const [currentTimeSec, setCurrentTimeSec] = useState(-1); //current time of the video in seconds
  const [durationSec, setDurationSec] = useState(-1); // // current duration of the video in seconds
  const [isProj1Active, setisProj1Active] = useState(false);
  const [isProj2Active, setIsProj2Active] = useState(true);
  const [isProj3Active, setIsProj3Active] = useState(false);
  const navigate = useNavigate();
  const proj1src = '';
  const proj2src = '/video/autolysis.gif';
  const proj3src = '';
  const proj2Link = settings.video2.URL;
  const proj1Link = settings.video1.URL;
  const proj3Link = settings.video3.URL;

  return (
    <div className="App">
      <div className='fullscreen landing bg-dark'>
        <div className='project-wrap'>
          {isProj1Active &&
            <a className='absolute h-full w-full' href={proj1Link}>
              <img className='object-cover object-center' src={proj1src} />
            </a>
          }
          <h2>{settings.video1.title}</h2>
          {!isProj1Active &&
            <a>
              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                <RiCalendarLine />
              </IconContext.Provider>
              {settings.video1.reminder}
            </a>
          }
          <img src={proj1src} />
        </div>
        <div className='project-wrap'>
          {isProj2Active && 
          <a className='absolute h-full w-full' href={proj2Link}>
            <img className='object-cover object-center' src={proj2src} />
          </a>
          }
          <h2>{settings.video2.title}</h2>
          {!isProj2Active && 
            <a>
              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                <RiCalendarLine />
              </IconContext.Provider>
              {settings.video2.reminder}
            </a>
          }
        </div>
        <div className='project-wrap'>
        {isProj3Active && 
          <a className='absolute h-full w-full' href={proj3Link}>
            <img className='object-cover object-center' src={proj3src} />
          </a>
        }
          <h2>{settings.video3.title}</h2>
          {!isProj3Active && 
            <a>
              <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                <RiCalendarLine />
              </IconContext.Provider>
              {settings.video3.reminder}
            </a>
          }
          <img src={proj3src} />
        </div>
      </div>
      <Nav />
    </div>
  );
}

const App = () => <Router><AppInner /></Router>;

export default App;
