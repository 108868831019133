import { IconContext } from 'react-icons';
import {  IoShareOutline, IoCloseOutline } from "react-icons/io5";
import { RiFullscreenLine, RiTwitterLine, RiFacebookLine } from "react-icons/ri";

export default function Nav() {
    return (
        <nav>
        <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
            <IoShareOutline />
        </IconContext.Provider>
        <div className='w-full flex justify-center bg-grey uppercase text-sm mt-2 lg:mt-6 py-2 gap-2'>
          <a className='underline' href="#">About playreplay</a> |  
          <div className='flex items-center gap-2'>
            Share
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <RiFacebookLine />
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
              <RiTwitterLine />
            </IconContext.Provider>
          </div>
        </div>
      </nav>
    );
}